import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Chip } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p>{`The Chip is a small graphic shape that used as indicator or symbol. It is a table version of chart legend.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`The Chips can be used in the table component to represent the objects such as tapes name, loan status, and so on.`}</p>
    <h2>{`Variations`}</h2>
    <p>{`There are four sizes of chips. Any colors can be used in chips.`}</p>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Chip color="bg-dataviz-red-500" size="small" mdxType="Chip" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx"
          }}>{`<Chip
  color="bg-dataviz-red-500"
  size="small"
/>
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Chip color="bg-dataviz-red-500" size="medium" mdxType="Chip" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx"
          }}>{`<Chip
  color="bg-dataviz-red-500"
  size="medium"
/>
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Chip color="bg-dataviz-red-500" size="large" mdxType="Chip" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx"
          }}>{`<Chip
  color="bg-dataviz-red-500"
  size="large"
/>
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Chip color="bg-dataviz-red-500" size="xl" mdxType="Chip" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/chip/Chip.tsx"
          }}>{`<Chip
  color="bg-dataviz-red-500"
  size="xl"
/>
`}</code></pre>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      